import { toPosition, createLatLng, createCircle, LocationDescriptor, LocationDescriptor_$reflection, createPosition } from "../MapLiteral.js";
import { some, map, defaultArg, value as value_2 } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { EventInfo_Default_7F9DDECF, EventInfo, EventInfo_$reflection } from "../../Omnicv.Shared.Common/CoreEventInfo.js";
import { union_type, record_type, bool_type, option_type, class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import * as common from "../../../src/javascript/common.js";
import { Session__GetCurrentProfileId } from "../SharedView.js";
import { toUniversalTime } from "../../fable_modules/fable-library-js.4.19.3/Date.js";
import { Event as Event$ } from "../../fable_modules/fable-library-js.4.19.3/Event.js";
import { compare, defaultOf, equals } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Config_variable, Config_placeLookupId } from "../Extensions.js";
import { item } from "../../fable_modules/fable-library-js.4.19.3/Array.js";
import { max, min } from "../../fable_modules/fable-library-js.4.19.3/Double.js";
import { PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "../../fable_modules/Fable.Promise.3.2.0/Promise.fs.js";
import { promise } from "../../fable_modules/Fable.Promise.3.2.0/PromiseImpl.fs.js";
import { Cmd_none, Cmd_batch, Cmd_OfPromise_attempt } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { head, length, sortBy, singleton } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { exnToDisplayString, dateStrToDateTime } from "../Utils.js";
import { add } from "../../fable_modules/fable-library-js.4.19.3/Observable.js";

const zeroPosition = createPosition(0, 0);

export function getOrElse(opt, elseValue) {
    if (opt == null) {
        return elseValue;
    }
    else {
        return value_2(opt);
    }
}

export class Model extends Record {
    constructor(EventInfo, AutocompleteService, Position, StrictBounds) {
        super();
        this.EventInfo = EventInfo;
        this.AutocompleteService = AutocompleteService;
        this.Position = Position;
        this.StrictBounds = StrictBounds;
    }
}

export function Model_$reflection() {
    return record_type("Omnicv.Client.Components.EventInfoManager.Model", [], Model, () => [["EventInfo", EventInfo_$reflection()], ["AutocompleteService", option_type(class_type("GoogleMapsApi.Google.Maps.Places.Autocomplete"))], ["Position", option_type(class_type("Browser.Types.Position"))], ["StrictBounds", bool_type]]);
}

export function Model__UpdateEventInfo_Z45EFA9A8(this$, newEventInfo) {
    return new Model(newEventInfo, this$.AutocompleteService, this$.Position, this$.StrictBounds);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Loaded", "UpdateLocation", "UpdatePosition", "GeoLocationUpdated", "UpdateAutocomplete", "AutocompleteFailure", "SetStrictBounds"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Components.EventInfoManager.Msg", [], Msg, () => [[["Item", EventInfo_$reflection()]], [["Item", option_type(LocationDescriptor_$reflection())]], [["Item", class_type("Browser.Types.Position")]], [], [["Item", class_type("GoogleMapsApi.Google.Maps.Places.Autocomplete")]], [["Item", class_type("System.Exception")]], [["Item", bool_type]]]);
}

export function prepareEventInfoForSubmit(session, model) {
    const source = model.EventInfo;
    const clientTz = defaultArg(source.ClientTz, common.getTimezoneName());
    const clientTzOffsetMin = defaultArg(source.ClientTzOffsetMin, common.getTimezoneOffset()) | 0;
    return new EventInfo(source.Id, source.ContentTypeId, Session__GetCurrentProfileId(session), toUniversalTime(source.EventTimestamp), source.CreatedAt, source.UpdatedAt, source.PlaceId, source.PlaceName, source.Latitude, source.Longitude, source.LocationTz, source.LocationTzOffsetMin, clientTz, clientTzOffsetMin);
}

const eventPublisher = new Event$();

export function triggerUpdateLocation(result) {
    eventPublisher.Trigger(new Msg(1, [result]));
}

function updateLocation(place) {
    const placeId = place.place_id;
    let newPosition;
    if ((place.geometry != null) && (value_2(place.geometry).location != null)) {
        const location = value_2(value_2(place.geometry).location);
        newPosition = createPosition(location.lat(), location.lng());
    }
    else {
        newPosition = zeroPosition;
    }
    let placeName;
    const name = getOrElse(place.name, "");
    const address = getOrElse(place.formatted_address, "");
    placeName = ((address.indexOf(name) === 0) ? address : ((name + ", ") + address));
    triggerUpdateLocation((placeId != null) ? (new LocationDescriptor(value_2(placeId), placeName, newPosition, undefined, map((value) => ~~value, place.utc_offset_minutes))) : undefined);
}

const registerPlacesAutoCompleteImpDelays = new Int32Array([1, 2, 4, 8, 16, 32]);

function registerPlacesAutoComplete() {
    const waitForElementPromise = (iteration, totalDelay, next) => {
        if (equals(document.getElementById(Config_placeLookupId), defaultOf())) {
            const delay = item(min(iteration, registerPlacesAutoCompleteImpDelays.length - 1), registerPlacesAutoCompleteImpDelays) | 0;
            const delayMs = (delay * 1000) | 0;
            console.log(some(`Will be waiting ${delay}sec after ${iteration} iterations and ${totalDelay}sec to get the Google map entry element`));
            const pr = new Promise(resolve => setTimeout(resolve, delayMs));
            return pr.then(() => waitForElementPromise(iteration + 1, totalDelay + delay, next));
        }
        else {
            console.log(some(`Went through ${iteration} iterations and waited ${totalDelay}sec to get the Google map entry element`));
            return next();
        }
    };
    console.log(some("Loading Google map library"));
    let pr_2;
    const pr_1 = common.loadGoogleMapsApi(Config_variable("GOOGLE_MAPS_API_KEY"));
    pr_2 = (pr_1.then((google_2) => {
        console.log(some(`Google map library loaded: ${google_2.maps.version}`));
        return waitForElementPromise(0, 0, () => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            let placesAutoComplete;
            return Promise.resolve((console.log(some("Google map library loaded, attaching element")), (placesAutoComplete = common.registerPlacesAutoComplete(google_2, Config_placeLookupId, (place) => {
                updateLocation(place);
            }), eventPublisher.Trigger(new Msg(4, [placesAutoComplete])))));
        })));
    }));
    return pr_2.catch((ex) => {
        console.error(some(`Failed to load Google map library: ${ex.message}`));
    });
}

const commonInitCmds = singleton(Cmd_OfPromise_attempt(registerPlacesAutoComplete, undefined, (Item) => (new Msg(5, [Item]))));

export function initCommon(eventInfo) {
    if (navigator.geolocation != null) {
        const geoLocation = value_2(navigator.geolocation);
        geoLocation.getCurrentPosition((arg_1) => {
            eventPublisher.Trigger(new Msg(2, [arg_1]));
        }, (error) => {
            console.error(some(`Failed to get position: ${error.code} ${error.message}`));
        });
    }
    return [new Model(eventInfo, undefined, undefined, true), Cmd_batch(commonInitCmds)];
}

export function initCommonNoRegistration(eventInfo) {
    return [new Model(eventInfo, undefined, undefined, true), Cmd_none()];
}

export function initNew(dateStr) {
    return initCommon(EventInfo_Default_7F9DDECF(dateStrToDateTime(dateStr)));
}

export function initEdit(id) {
    let bind$0040;
    return initCommon((bind$0040 = EventInfo_Default_7F9DDECF(common.getNow()), new EventInfo(id, bind$0040.ContentTypeId, bind$0040.ProfileId, bind$0040.EventTimestamp, bind$0040.CreatedAt, bind$0040.UpdatedAt, bind$0040.PlaceId, bind$0040.PlaceName, bind$0040.Latitude, bind$0040.Longitude, bind$0040.LocationTz, bind$0040.LocationTzOffsetMin, bind$0040.ClientTz, bind$0040.ClientTzOffsetMin)));
}

function updateAutocompleteService(model) {
    const matchValue = model.AutocompleteService;
    const matchValue_1 = model.EventInfo.Latitude;
    const matchValue_2 = model.EventInfo.Longitude;
    let matchResult, lat, lng, service;
    if (matchValue != null) {
        if (matchValue_1 != null) {
            if (matchValue_2 != null) {
                matchResult = 0;
                lat = matchValue_1;
                lng = matchValue_2;
                service = matchValue;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0: {
            const circle = createCircle(createLatLng(lat, lng), 21000);
            const bounds = value_2(circle.getBounds());
            const options_1 = {
                bounds: bounds,
                strictBounds: model.StrictBounds,
            };
            service.setOptions(options_1);
            break;
        }
        case 1: {
            break;
        }
    }
}

export function eventInfoCoordinatesToPosition(eventInfo) {
    return toPosition(eventInfo.Latitude, eventInfo.Longitude);
}

export function eventInfoCoordinatesToPositionOrDefault(eventInfo) {
    const matchValue = eventInfoCoordinatesToPosition(eventInfo);
    if (matchValue != null) {
        return matchValue;
    }
    else {
        return zeroPosition;
    }
}

export function tryUpdateLocationFromSuggestions(suggestionsFunc, model) {
    const eventInfo = model.EventInfo;
    const matchValue = model.Position;
    let matchResult, currentPosition;
    if (matchValue != null) {
        if (eventInfo.PlaceId == null) {
            if (eventInfo.PlaceName == null) {
                matchResult = 0;
                currentPosition = matchValue;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0: {
            const candidates = sortBy((value) => value.DistanceFromCurrent, suggestionsFunc(), {
                Compare: compare,
            });
            if (length(candidates) > 0) {
                const best = head(candidates);
                const accuracy = max(100, min(currentPosition.coords.accuracy, 500));
                if ((best.DistanceFromCurrent != null) && (value_2(best.DistanceFromCurrent) <= accuracy)) {
                    console.log(some(`Setting location to ${best}`));
                    triggerUpdateLocation(best);
                }
            }
            break;
        }
        case 1: {
            break;
        }
    }
}

export function update(msg, model) {
    let bind$0040_1, bind$0040, bind$0040_2, position, msg_1;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0] == null) {
                return [new Model((bind$0040_1 = model.EventInfo, new EventInfo(bind$0040_1.Id, bind$0040_1.ContentTypeId, bind$0040_1.ProfileId, bind$0040_1.EventTimestamp, bind$0040_1.CreatedAt, bind$0040_1.UpdatedAt, undefined, undefined, undefined, undefined, bind$0040_1.LocationTz, bind$0040_1.LocationTzOffsetMin, bind$0040_1.ClientTz, bind$0040_1.ClientTzOffsetMin)), model.AutocompleteService, model.Position, model.StrictBounds), Cmd_none()];
            }
            else {
                const descriptor = msg.fields[0];
                return [new Model((bind$0040 = model.EventInfo, new EventInfo(bind$0040.Id, bind$0040.ContentTypeId, bind$0040.ProfileId, bind$0040.EventTimestamp, bind$0040.CreatedAt, bind$0040.UpdatedAt, descriptor.PlaceId, descriptor.Name.trim(), descriptor.Position.coords.latitude, descriptor.Position.coords.longitude, bind$0040.LocationTz, descriptor.UtcOffsetMin, bind$0040.ClientTz, bind$0040.ClientTzOffsetMin)), model.AutocompleteService, model.Position, model.StrictBounds), Cmd_none()];
            }
        case 2: {
            console.log(some(`New coordinates: ${msg.fields[0].coords.latitude} / ${msg.fields[0].coords.longitude}; accuracy=${msg.fields[0].coords.accuracy}`));
            const newModel = new Model((bind$0040_2 = model.EventInfo, new EventInfo(bind$0040_2.Id, bind$0040_2.ContentTypeId, bind$0040_2.ProfileId, bind$0040_2.EventTimestamp, bind$0040_2.CreatedAt, bind$0040_2.UpdatedAt, bind$0040_2.PlaceId, bind$0040_2.PlaceName, msg.fields[0].coords.latitude, msg.fields[0].coords.longitude, bind$0040_2.LocationTz, bind$0040_2.LocationTzOffsetMin, bind$0040_2.ClientTz, bind$0040_2.ClientTzOffsetMin)), model.AutocompleteService, msg.fields[0], model.StrictBounds);
            updateAutocompleteService(newModel);
            return [newModel, singleton((dispatch_1) => {
                dispatch_1(new Msg(3, []));
            })];
        }
        case 3:
            return [model, Cmd_none()];
        case 4: {
            const newModel_1 = new Model(model.EventInfo, msg.fields[0], model.Position, model.StrictBounds);
            updateAutocompleteService(newModel_1);
            return [newModel_1, Cmd_none()];
        }
        case 5: {
            console.log(some(exnToDisplayString(msg.fields[0])));
            return [model, Cmd_none()];
        }
        case 6: {
            const newModel_2 = new Model(model.EventInfo, model.AutocompleteService, model.Position, msg.fields[0]);
            updateAutocompleteService(newModel_2);
            return [newModel_2, Cmd_none()];
        }
        default:
            return [new Model(msg.fields[0], model.AutocompleteService, model.Position, model.StrictBounds), (position = eventInfoCoordinatesToPosition(msg.fields[0]), (position != null) ? ((msg_1 = (new Msg(2, [value_2(position)])), singleton((dispatch) => {
                dispatch(msg_1);
            }))) : Cmd_none())];
    }
}

export function subscribeLocation(dispatch) {
    add((msg) => {
        dispatch(msg);
    }, eventPublisher.Publish);
}

